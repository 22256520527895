import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfigDrivenContainerLayout from '../components/Layout/ConfigDrivenContainerLayout';
import LoanCard from '../components/Loans/LoanCard';
import LoansTitle from '../components/Loans/LoansTitle';
import { NewApplicationConfirmationModal } from '../components/NewApplicationConfirmationModal.tsx';
import { LoanDto } from '../openapi/atlantis';
import { fetchLoans, getLoanBorrowerId } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';
import { RootState } from '../types';

interface LoansRouteProps {}

const LoansRoute: React.FC<LoansRouteProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showStartApplicationModal, setShowStartApplicationModal] =
    useState(false);
  const { loans } = useSelector((state: RootState) => ({
    loans: state.loan.loans,
  }));

  const onNewApplication = () => {
    navigate('/loan-type');
  };

  useEffect(() => {
    dispatch(fetchLoans());
  }, [dispatch]);

  const onLoanClick = async (loan: LoanDto) => {
    const borrowerId = await dispatch(getLoanBorrowerId(loan));
    if (borrowerId) {
      navigate(`/pre-approval-form/${borrowerId}`);
    }
  };

  return (
    <ConfigDrivenContainerLayout hideMyLoans hideMyTasks hideChat>
      <div className='h-full w-full flex flex-col justify-between items-center'>
        <div className='w-full'>
          <div className='lg:my-10 mb-10'>
            <LoansTitle />
          </div>
          {(loans || []).map((loan) => (
            <div key={loan.id} className='mb-5'>
              <LoanCard loan={loan} onPress={onLoanClick} />
            </div>
          ))}
        </div>
        <div
          onClick={() => setShowStartApplicationModal(true)}
          className='py-3 px-5 bg-reskin-regent-200 w-full rounded-lg flex flex-col cursor-pointer group max-w-[500px]'
        >
          <div className='flex justify-center items-center gap-2'>
            <FontAwesomeIcon
              icon={faPlus}
              className='bg-white border border-reskin-regent-300 p-[6px] rounded-md text-sm lg:text-base'
            />
            <div className='flex flex-wrap justify-center gap-1 text-sm'>
              <p className='whitespace-nowrap text-reskin-primary-navy font-poppins-regular hidden sm:block'>
                Ready for your next loan?
              </p>
              <p className='whitespace-nowrap font-poppins-light text-reskin-primary-blue group-hover:underline'>
                Start another application
              </p>
            </div>
          </div>
        </div>
      </div>
      <NewApplicationConfirmationModal
        isOpen={showStartApplicationModal}
        onClose={() => setShowStartApplicationModal(false)}
        handleStartApplication={onNewApplication}
      />
    </ConfigDrivenContainerLayout>
  );
};
export default LoansRoute;
