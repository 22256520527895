import { faCalendar, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useFormContext } from 'react-hook-form';
import { FieldObject, FieldObjectTypeEnum } from '../../../openapi/atlantis';
import { useAppSelector } from '../../../slices/store';
import { DisplayTypeInputEnum } from '../../../utils/ConfigDrivenFormUtils';
import { getTextMaskRegexFromDisplayType } from '../../../utils/MaskUtils';
import {
  EMAIL_VALIDATIONS,
  getDatePickerConfig,
  getInputValidations,
} from '../../../utils/Validations';
import ControlledBinaryInput from '../../inputs/ControlledBinaryInput';
import ControlledButtonInput from '../../inputs/ControlledButtonInput';
import ControlledConsentCheckboxInput from '../../inputs/ControlledConsentCheckboxInput';
import ControlledCurrencyInput from '../../inputs/ControlledCurrencyInput';
import ControlledDatePicker from '../../inputs/ControlledDatePickerInput';
import ControlledFileInput from '../../inputs/ControlledFileInput';
import ControlledMaskTextInput from '../../inputs/ControlledMaskTextInput';
import ControlledMultiSelectInput from '../../inputs/ControlledMultiSelectInput';
import ControlledPhoneNumberInput from '../../inputs/ControlledPhoneNumberInput';
import ControlledSingleSelectDropDownInput from '../../inputs/ControlledSingleSelectDropDownInput';
import ControlledSingleSelectImageInput from '../../inputs/ControlledSingleSelectImageInput';
import ControlledSingleSelectInput from '../../inputs/ControlledSingleSelectInput';
import ControlledTextInput from '../../inputs/ControlledTextInput';
import ControlledToggleInput from '../../inputs/ControlledToggleInput';
import ControlledUrlRequestInput from '../../inputs/ControlledUrlRequestInput';
import AddressInput from '../Misc/AddressInput';
import SignatureComponent from '../../inputs/SignatureComponent';
import ControlledReactSelectInput from '../../inputs/ControlledReactSelectInput';
import ControlledSingleSelectCardInput from '../../inputs/ControlledSingleSelectCardInput';
import ControlledSingleFileInput from '../../inputs/ControlledSingleFileInput';

interface ConfigDrivenFormInputProps {
  name: string;
  field: FieldObject;
  onSubmit: (formData: any) => void;
  isSubmitting: boolean;
  checklistItemId: string;
}

const ConfigDrivenFormInput: React.FC<ConfigDrivenFormInputProps> = ({
  name,
  field,
  onSubmit,
  isSubmitting,
  checklistItemId,
}) => {
  const { borrowerDetail } = useAppSelector((state) => state.loan);
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useFormContext();

  if (field.type === FieldObjectTypeEnum.Binary) {
    return (
      <ControlledBinaryInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        options={[field?.trueOption, field?.falseOption]}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Text) {
    const mask = getTextMaskRegexFromDisplayType(
      field.fieldStyle?.displayType!,
    );

    if (mask) {
      return (
        <ControlledMaskTextInput
          name={name}
          mask={mask}
          control={control}
          label={field.label}
          //@ts-ignore
          placeholder={field.placeholder}
          rules={getInputValidations(field)}
        />
      );
    }

    return (
      <ControlledTextInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Email) {
    return (
      <ControlledTextInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        type='email'
        startAdornment={
          <FontAwesomeIcon
            icon={faEnvelope}
            fontSize={20}
            className='text-primary-skyblue'
          />
        }
        rules={{
          ...EMAIL_VALIDATIONS,
          ...getInputValidations(field),
        }}
      />
    );
  }

  if (field.type === 'NUMBER') {
    return (
      <ControlledTextInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        type='number'
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Phone) {
    return (
      <ControlledPhoneNumberInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Money) {
    return (
      <ControlledCurrencyInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.SingleSelect) {
    const displayType = field.fieldStyle?.displayType;

    if (displayType === DisplayTypeInputEnum.SINGLE_SELECT_IMAGE) {
      return (
        <ControlledSingleSelectImageInput
          name={name}
          control={control}
          label={field.label}
          //@ts-ignore
          options={field.options}
          rules={getInputValidations(field)}
        />
      );
    }

    if (displayType === DisplayTypeInputEnum.SINGLE_SELECT_DROP_DOWN) {
      return (
        <ControlledSingleSelectDropDownInput
          name={name}
          control={control}
          label={field.label}
          //@ts-ignore
          placeholder={field.placeholder}
          //@ts-ignore
          options={field.options}
          rules={getInputValidations(field)}
        />
      );
    }

    if (displayType === DisplayTypeInputEnum.SINGLE_SELECT_IMAGE_DESCRIPTION) {
      return (
        <ControlledSingleSelectCardInput
          name={name}
          control={control}
          //@ts-ignore
          options={field.options}
          rules={getInputValidations(field)}
        />
      );
    }

    return (
      <ControlledSingleSelectInput
        name={name}
        label={field.label}
        control={control}
        //@ts-ignore
        options={field.options}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.MultiSelect) {
    const displayType = field.fieldStyle?.displayType;

    if (displayType === 'dropdown') {
      return (
        <ControlledReactSelectInput
          name={name}
          label={field.label}
          control={control}
          //@ts-ignore
          options={field.options}
          rules={getInputValidations(field)}
          isMulti
        />
      );
    }

    return (
      <ControlledMultiSelectInput
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        options={field.options}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Date) {
    return (
      <ControlledDatePicker
        name={name}
        control={control}
        label={field.label}
        //@ts-ignore
        placeholder={field.placeholder}
        icon={
          <FontAwesomeIcon
            icon={faCalendar}
            fontSize={20}
            className='text-primary-skyblue'
          />
        }
        datePickerConfig={getDatePickerConfig(field)}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Toggle) {
    const displayType = field.fieldStyle?.displayType;

    if (displayType === DisplayTypeInputEnum.CONSENT_CHECKBOX) {
      return (
        <ControlledConsentCheckboxInput
          name={name}
          label={field.label}
          control={control}
          //@ts-ignore
          trueOption={field?.trueOption}
          //@ts-ignore
          falseOption={field?.falseOption}
          //@ts-ignore
          defaultValue={field?.presetAnswer?.toString()}
          rules={getInputValidations(field)}
        />
      );
    }

    return (
      <ControlledToggleInput
        name={name}
        label={field.label}
        control={control}
        //@ts-ignore
        trueOption={field?.trueOption}
        //@ts-ignore
        falseOption={field?.falseOption}
        //@ts-ignore
        defaultValue={field?.presetAnswer?.toString()}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Address) {
    return <AddressInput name={name} field={field} />;
  }

  if (field.type === FieldObjectTypeEnum.FileRequest) {
    const displayType = field.fieldStyle?.displayType;

    if (displayType === DisplayTypeInputEnum.SINGLE_FILE) {
      return (
        <ControlledSingleFileInput
          name={name}
          control={control}
          checklistItemId={checklistItemId}
          rules={getInputValidations(field)}
        />
      );
    }

    return (
      <ControlledFileInput
        name={name}
        label={field.label}
        control={control}
        checklistItemId={checklistItemId}
        //@ts-ignore
        sendTo={borrowerDetail?.dropboxEmailAddress}
        //@ts-ignore
        options={[field?.trueOption, field?.falseOption]}
        rules={getInputValidations(field)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.UrlRequest) {
    return (
      <ControlledUrlRequestInput
        name={name}
        control={control}
        label={field.label!}
        //@ts-ignore
        urlSourcePath={field.urlSourcePath!}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.SignatureRequest) {
    return (
      <SignatureComponent
        name={name}
        field={field}
        documentSignedCallback={handleSubmit(onSubmit)}
      />
    );
  }

  if (field.type === FieldObjectTypeEnum.Button) {
    return (
      <ControlledButtonInput
        name={name}
        control={control}
        label={field.label!}
        containerStyle='mt-10'
        textStyle={isMobile ? '!text-lg' : '!text-2xl'}
        buttonSize={isMobile ? 'sm' : 'md'}
        rightIcon={
          <FontAwesomeIcon
            icon={faChevronsRight}
            fontSize={16}
            className='text-white'
          />
        }
        gradientVariant='mintyfresh'
        isDisabled={!isValid || !isEmpty(errors)}
        isSubmitting={isSubmitting}
        onPress={handleSubmit(onSubmit)}
      />
    );
  }

  return undefined;
};

export default ConfigDrivenFormInput;
