import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useScroll from '../../hooks/useScroll.ts';
import {
  AnswerApplicationQuestionRequest,
  ApplicationStep,
} from '../../openapi/atlantis';
import ErrorService from '../../services/ErrorService.ts';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch } from '../../slices/store';
import { NextActionType } from '../../utils/ConfigDrivenFormUtils.tsx';
import Logger from '../../utils/Logger';
import {
  isQuestion,
  isSectionIntro,
  isSectionOutro,
} from '../../utils/TypeUtils';
import DefaultSplash from '../DefaultSplash';
import ConfigDrivenIntroContainer from './Containers/ConfigDrivenIntroContainer';
import ConfigDrivenOutroContainer from './Containers/ConfigDrivenOutroContainer';
import ConfigDrivenQuestionContainer from './Containers/ConfigDrivenQuestionContainer';
import ConfigDrivenOnboardingOutroContainer from './Containers/ConfigDrivenOnboardingOutroContainer.tsx';

interface ConfigDrivenContainerProps {
  onStart: (stepId: string) => void;
  onNext: (actionType: NextActionType) => Promise<ApplicationStep>;
  onSubmit: (formData: AnswerApplicationQuestionRequest) => Promise<void>;
  onNavigateToQuestion: (questionId: string) => Promise<ApplicationStep>;
}

const ConfigDrivenContainer: React.FC<ConfigDrivenContainerProps> = ({
  onStart,
  onNext,
  onSubmit,
  onNavigateToQuestion,
}) => {
  const { applicationId } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<ApplicationStep>();
  const { scrollToTop } = useScroll();

  const handleOnNext = async (
    actionType: NextActionType = NextActionType.BUTTON_ACTION,
  ) => {
    try {
      const data = await onNext(actionType);
      setConfig(data);
      scrollToTop();
    } catch (e: any) {
      Logger.error('Failed to fetch next question:', e);
      ErrorService.notify('Failed to fetch next question', e);
      dispatch(
        showErrorToast(
          'Failed to fetch next question. Please try again in sometime',
        ),
      );
    }
  };

  const handleNavigateToQuestion = async (questionId: string) => {
    try {
      const data = await onNavigateToQuestion(questionId);
      setConfig(data);
    } catch (e: any) {
      Logger.error('Failed to fetch question by id:', e);
      ErrorService.notify('Failed to fetch question by id', e);
      dispatch(
        showErrorToast(
          'Failed to fetch question. Please try again in sometime',
        ),
      );
    }
  };

  const fetchQuestion = useCallback(async () => {
    setIsLoading(true);
    await handleOnNext(NextActionType.INITIATE);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchQuestion();
  }, [fetchQuestion]);

  if (isLoading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  if (isSectionIntro(config!)) {
    return (
      <ConfigDrivenIntroContainer
        config={config}
        onNext={handleOnNext}
        onStart={onStart}
      />
    );
  }

  if (isQuestion(config!)) {
    return (
      <ConfigDrivenQuestionContainer
        config={config}
        onNext={handleOnNext}
        onSubmit={onSubmit}
        onNavigateToQuestion={handleNavigateToQuestion}
      />
    );
  }

  if (isSectionOutro(config!)) {
    if (applicationId) {
      return <ConfigDrivenOnboardingOutroContainer config={config} />;
    }

    return <ConfigDrivenOutroContainer config={config} />;
  }

  return undefined;
};

export default ConfigDrivenContainer;
