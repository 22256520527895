import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { SectionOutro } from '../../../openapi/atlantis';
import { fetchLoanProgress } from '../../../slices/ApplicationSlice.ts';
import { useAppDispatch, useAppSelector } from '../../../slices/store';
import { ApplicationStepWithNewContent } from '../../../utils/ConfigDrivenFormUtils';
import { formatPhoneNumber } from '../../../utils/StringUtils.ts';
import Button from '../../Button';
import Confetti from '../../Confetti';
import ConfigDrivenContainerLayout from '../../Layout/ConfigDrivenContainerLayout';

interface ConfigDrivenOutroContainerProps {
  config: ApplicationStepWithNewContent<SectionOutro>;
}

const ConfigDrivenOutroContainer = ({
  config,
}: ConfigDrivenOutroContainerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { borrowerDetail, loanDetail } = useAppSelector((state) => state.loan);
  const { id } = config.content || {};
  const loanOfficerPhoneNumber = loanDetail?.loanOfficer?.phoneNumber;

  const handleOnMyTasks = async () => {
    await dispatch(fetchLoanProgress(borrowerDetail?.roadToSuccessId!));
    navigate('tasks');
  };

  return (
    <ConfigDrivenContainerLayout hideMyTasks>
      <div key={id}>
        <div>
          <div>
            <p className='font-poppins-medium text-reskin-primary-dark md:text-5xl text-3xl mt-2'>
              Your <span className='text-primary-skyblue'>application</span> is
              in!
            </p>
            <p className='font-inter-light text-base mt-8'>
              <span className='font-inter-semibold'>Well done!</span> You have
              just submitted your application. You can go to the My Tasks page
              to continue.
            </p>
          </div>
          <div className='bg-whitesmoke p-2 rounded-lg mt-5'>
            <div className='flex flex-row items-center border-b border-b-silver p-2'>
              <FontAwesomeIcon
                icon={faCircleQuestion}
                fontSize={16}
                className='text-aqua'
              />
              <p className='font-poppins-medium text-base ml-2'>Questions?</p>
            </div>
            <div className='p-2'>
              <div className='flex flex-row items-center'>
                <p className='font-inter-regular text-base text-black'>
                  Call us at{' '}
                </p>
                <a href={`tel:${loanOfficerPhoneNumber}`} className='pl-2'>
                  <p className='font-inter-medium text-base text-primary-skyblue'>
                    {formatPhoneNumber(loanOfficerPhoneNumber)}
                  </p>
                </a>
              </div>
              <p className='font-inter-regular text-sm text-primary-graphite'>
                Monday - Friday, 9am - 9pm Eastern.
              </p>
            </div>
          </div>
          <div className='mt-8'>
            <Button
              label='My Tasks'
              gradientVariant='mintyfresh'
              buttonSize='sm'
              containerStyle='border'
              onPress={handleOnMyTasks}
              fullWidth
            />
          </div>
        </div>
        <Confetti />
      </div>
    </ConfigDrivenContainerLayout>
  );
};

export default ConfigDrivenOutroContainer;
