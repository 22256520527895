import { faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { FileResponse } from '../../openapi/dropbox';
import { useAppSelector } from '../../slices/store';
import DocumentCard from './DocumentCard';

type DocumentsListProps = {
  files: FileResponse[];
};

const DocumentsList: React.FC<DocumentsListProps> = ({ files = [] }) => {
  const { preApprovalLetters } = useAppSelector((state) => ({
    preApprovalLetters: state.loan.loanDetail?.preApprovalLetters,
  }));

  const preApprovalFileIds = useMemo(() => {
    return [...(preApprovalLetters || [])].map((letter) => letter.fileId);
  }, [preApprovalLetters]);

  if (files.length === 0) {
    return (
      <div className='w-full h-[60vh] flex flex-col justify-center items-center'>
        <div className='mb-6'>
          <FontAwesomeIcon
            className='text-reskin-silver-400'
            icon={faFolderOpen}
            fontSize={54}
          />
        </div>
        <div className='font-inter-light text-base text-reskin-primary-dark'>
          No Documents
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='mt-12 mb-4'>Documents Uploaded ({files.length})</div>
      {files.map((file) => (
        <div key={file.id} className='mb-4'>
          <DocumentCard
            file={file}
            hideDelete={preApprovalFileIds.includes(file.id!)}
          />
        </div>
      ))}
    </div>
  );
};

export default DocumentsList;
