import { faCircleInfo, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useFormContext } from 'react-hook-form';
import { FieldObject } from '../../openapi/atlantis';
import { SigneeDocumentControllerApi } from '../../openapi/signature-api';
import {
  AgreementResponse,
  UserAgreementControllerApi,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { SignatureWidgetModeEnum } from '../../types';
import Logger from '../../utils/Logger';
import {
  getSignatureApiConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import Hover from '../Hover';
import DefaultLoader from '../DefaultLoader';
import SignatureWidget from './SignatureWidget';

interface SignatureComponentProps {
  name: string;
  field: FieldObject;
  documentSignedCallback?: () => void;
}

const SignatureComponent: React.FC<SignatureComponentProps> = ({
  name,
  field,
  documentSignedCallback,
}) => {
  const dispatch = useAppDispatch();
  const {
    auth: { authUserDetail },
  } = useAppSelector((state) => state);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [agreementData, setAgreementData] = useState<AgreementResponse>({});

  const { setValue, setError, clearErrors, trigger } = useFormContext();

  const handleNextButton = useCallback(async () => {
    try {
      setError('submit', {
        type: 'manual',
        message: 'Please sign the agreement to proceed',
      });
      const { data } = await new SigneeDocumentControllerApi(
        getSignatureApiConfiguration(),
      ).getDocumentFromToken(agreementData.signeeToken!);

      if (data.signed) {
        clearErrors();
        trigger();
        setValue(name, agreementData.agreementId!);
      } else {
        setIsOpen(true);
      }
    } catch (e: any) {
      Logger.error('Failed to get document data', e);
      ErrorService.notify('Failed to get document data', e);
    }
  }, [
    agreementData.agreementId,
    agreementData.signeeToken,
    clearErrors,
    name,
    setError,
    setValue,
    trigger,
  ]);

  const fetchAgreementData = useCallback(async () => {
    try {
      const { data } = await new UserAgreementControllerApi(
        await getYentaConfiguration(),
      ).createAgreementByType({
        userId: authUserDetail?.id!,
        agreementType: (field as any).agreementType,
      });
      setAgreementData(data);
      setIsLoading(false);
      await handleNextButton();
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'Unable to fetch agreement data. Please try again after sometime.',
        ),
      );
      ErrorService.notifyIgnoreAuthErrors(
        'Unable to fetch user agreement data',
        e,
      );
    }
  }, [authUserDetail?.id, dispatch, field, handleNextButton]);

  useEffect(() => {
    fetchAgreementData();
  }, [fetchAgreementData]);

  const handleDocumentSigned = async () => {
    try {
      await new UserAgreementControllerApi(
        await getYentaConfiguration(),
      ).signAgreement({
        userId: authUserDetail?.id!,
        agreementId: agreementData.agreementId!,
      });
      clearErrors();
      trigger();
      setValue(name, agreementData.agreementId!);
      setIsOpen(false);
      if (documentSignedCallback) {
        documentSignedCallback();
      }
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'Unable to sign agreement. Please try again after sometime.',
        ),
      );
      ErrorService.notifyIgnoreAuthErrors('Unable to sign user agreement', e, {
        agreementData,
      });
    }
  };

  if (isLoading) {
    return <DefaultLoader />;
  }

  const onClose = () => {
    setIsOpen(false);
  };

  if (!isOpen) {
    const splitText = (label: string) => {
      // Regex to match the text inside <a> tag
      const regex = /<a[^>]*>(.*?)<\/a>/;
      const match = label.match(regex);

      if (match) {
        const text1 = label.split(match[0])[0]; // Text before <a> tag
        const text2 = match[1]; // Text inside <a> tag
        const text3 = label.split(match[0])[1]; // Text after <a> tag
        return { text1, text2, text3 };
      }

      return { text1: '', text2: label, text3: '' };
    };

    const { text1, text2, text3 } = splitText(field.label!);

    return (
      <div className='mt-5'>
        {text1}
        <span
          className='cursor-pointer text-primary-blue'
          onClick={() => setIsOpen(true)}
        >
          {text2}
        </span>
        {text3}
      </div>
    );
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='zen-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full lg:p-0'>
        <div className='rounded-xl z-10 relative' aria-label='simple-modal'>
          <div className='w-full lg:min-w-[65rem] relative shadow-zen-web bg-white rounded-2xl'>
            <div className='flex flex-row items-center bg-zen-light-blue rounded-t-2xl px-4 py-4'>
              <div className='flex-grow flex-col items-center justify-center'>
                <p className='font-zen-body font-semibold text-zen-dark-9 text-lg text-center'>
                  Please sign the document
                </p>
              </div>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className='text-zen-dark-5 text-2xl cursor-pointer'
                onClick={onClose}
              />
            </div>
            <div className='flex flex-row items-center bg-zen-light-gray-2 px-4 md:px-8 py-4'>
              <div className='flex flex-grow flex-col items-center justify-center'>
                <p className='font-zen-body font-normal text-zen-dark-9 text-base text-center flex flex-row items-center justify-center'>
                  We kindly ask you to review and put your digital signature on
                  the updated version.
                  <Hover
                    hoverComponent={
                      <div className='text-zen-dark-9 font-semibold text-sm font-zen-body p-2'>
                        You can return to sign later by clicking the Sign button
                        under Contracts in your Dashboard.
                      </div>
                    }
                    config={{
                      trigger: 'hover',
                      placement: isMobile ? 'top-end' : 'right',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className='ml-2 text-zen-dark-9 cursor-pointer'
                    />
                  </Hover>
                </p>
                <p className='font-zen-body font-normal text-zen-dark-9 text-base text-center'>
                  This ensures uninterrupted access to all the amazing features
                  reZEN has to offer!
                </p>
              </div>
            </div>
            <div
              className='w-full flex flex-col bg-grey-100 h-[400px] lg:h-[550px] overflow-y-hidden rounded-b-2xl'
              aria-label='sign-ica'
            >
              <SignatureWidget
                token={agreementData.signeeToken!}
                mode={SignatureWidgetModeEnum.SIGN}
                onDocumentSigned={handleDocumentSigned}
                onDocumentAlreadySigned={handleDocumentSigned}
                showDownload
                showMobileTooltip
                isLoading={false}
                browserMode
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureComponent;
