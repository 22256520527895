import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form';
import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import {
  ChecklistApi,
  FileReferenceDto,
  ItemApi,
} from '../../openapi/sherlock';
import { BorrowerDto } from '../../openapi/atlantis';
import { uploadFileToDropbox } from '../../slices/DropboxSlice';
import { getSherlockConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import { getDropboxFilesFromFileReferences } from '../../utils/DropboxUtils';
import { bytesToSize, isDocSizeInvalid } from '../../utils/FileUtils';
import { MAX_FILE_SIZE } from '../../constants/FileConstants';
import { cn } from '../../utils/classUtils';
import DefaultLoader from '../DefaultLoader';
import FileRow from '../FileRow';
import FieldErrorMessage from './FieldErrorMessage';

export interface ControlledFileInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  checklistItemId: string;
}

const ControlledSingleFileInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  checklistItemId,
  shouldUnregister = true,
  rules,
  ...controllerProps
}: ControlledFileInputProps<TFieldValues, TName>) => {
  const {
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    name,
    shouldUnregister,
  });

  const dispatch = useAppDispatch();
  const {
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const {
    dropbox: { dropboxByBorrowerId },
    auth: { authUserDetail },
  } = useAppSelector((state) => state);
  const dropbox = dropboxByBorrowerId[authUserDetail?.id!];
  const [uploading, setUploading] = useState<boolean>(false);

  const [fileReferences, setFileReferences] = useState<FileReferenceDto[]>();

  const uploadFile = async (file: any) => {
    setUploading(true);
    const authUserId = authUserDetail?.id!;

    const borrowerData = {
      id: authUserId,
      dropboxId: authUserDetail?.dropboxId,
    } as BorrowerDto;

    const fileResponse = await dispatch(
      uploadFileToDropbox(authUserId, borrowerData, file),
    );

    if (fileResponse) {
      const references = [
        {
          fileId: fileResponse?.id!,
          filename: fileResponse?.filename!,
        },
      ];

      try {
        await new ItemApi(await getSherlockConfiguration()).addFileReferences(
          checklistItemId,
          {
            references,
          },
        );
        await fetchChecklistItemById(checklistItemId);
      } catch (e: any) {
        dispatch(
          showErrorToast(
            'Unable to attach the document to checklist item. Please try again after sometime.',
          ),
        );
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to attach the dropbox document references to checklist item',
          e,
          {
            data: { checklistItemId, references },
          },
        );
      }
    }
    setUploading(false);
  };

  const fetchChecklistItemById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new ChecklistApi(
          await getSherlockConfiguration(),
        ).getChecklistItemById(id);
        setFileReferences(data.fileReferences?.references || []);
      } catch (e: any) {
        dispatch(
          showErrorToast(
            'Unable to fetch the checklist item. Please try again after sometime.',
          ),
        );
        ErrorService.notify('Unable to fetch checklist item', e, {
          data: {
            checklistItemId: id,
          },
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    fetchChecklistItemById(checklistItemId);
  }, [checklistItemId, fetchChecklistItemById]);

  const files = getDropboxFilesFromFileReferences(
    fileReferences || [],
    dropbox!,
  );

  const isFileAvailable = files.length > 0;
  const fileInputRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const currentValue = getValues(name);

    if (isFileAvailable) {
      if (!currentValue) {
        setValue(name as string, true);
      }
      if (errors[name]) {
        clearErrors(name);
      }
    } else {
      if (rules?.required && !errors[name]) {
        setError(name, {
          type: 'manual',
          message: '',
        });
      }
      setValue(name as string, false);
    }
  }, [
    clearErrors,
    errors,
    getValues,
    isFileAvailable,
    name,
    rules,
    setError,
    setValue,
  ]);

  return (
    <>
      <div className='bg-gray-2 border border-dashed border-gray-3 rounded-xl p-6'>
        <div className='flex flex-col items-center justify-center'>
          <FontAwesomeIcon
            icon={faCloudArrowUp}
            fontSize={30}
            className='text-primary-gray'
          />
          <p className='font-inter-medium text-base text-black mt-4'>
            Upload Document
          </p>
          <p className='font-inter-medium text-xs text-primary-gray mt-2'>
            File must be less than {bytesToSize(MAX_FILE_SIZE)}.
          </p>
          <div className='flex flex-row mt-3 cursor-pointer'>
            <div
              className={cn(
                'flex flex-row items-center justify-center px-5 py-3 rounded-lg border ',
                isFileAvailable
                  ? 'border-gray-3 cursor-not-allowed'
                  : 'border-gray-4',
              )}
              onClick={() => fileInputRef.current?.click()}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={async (e) => {
                e.preventDefault();

                if (isFileAvailable) {
                  return;
                }

                if (isDocSizeInvalid(e.dataTransfer.files![0], MAX_FILE_SIZE)) {
                  dispatch(
                    showErrorToast(
                      `File size exceeds maximum limit of ${bytesToSize(
                        MAX_FILE_SIZE,
                      )}.`,
                    ),
                  );
                } else {
                  uploadFile(e.dataTransfer.files![0]);
                }
              }}
            >
              <input
                disabled={isFileAvailable}
                data-testid='single-file-input'
                ref={fileInputRef as unknown as LegacyRef<HTMLInputElement>}
                className='hidden w-full h-full'
                type='file'
                accept='.xlsx, .xls, .doc, .docx, .pdf, image/*'
                onChange={(e) => {
                  if (isDocSizeInvalid(e.target.files![0], MAX_FILE_SIZE)) {
                    dispatch(
                      showErrorToast(
                        `File size exceeds maximum limit of ${bytesToSize(
                          MAX_FILE_SIZE,
                        )}.`,
                      ),
                    );
                  } else {
                    uploadFile(e.target.files![0]);
                  }
                  e.target.value = '';
                }}
              />
              <p
                className={cn(
                  'font-inter-medium text-xs whitespace-nowrap',
                  isFileAvailable
                    ? 'text-gray-3 cursor-not-allowed select-none'
                    : 'text-black',
                )}
              >
                Upload File
              </p>
              {uploading && (
                <div className='pl-4'>
                  <DefaultLoader iconSize={10} noPadding />
                </div>
              )}
            </div>
          </div>
        </div>
        {isFileAvailable && (
          <div className='border-t border-gray-3 pt-4 mt-4'>
            {files.map((file) => {
              return <FileRow file={file} key={file.id} />;
            })}
          </div>
        )}
      </div>

      <FieldErrorMessage message={error?.message} />
    </>
  );
};

export default ControlledSingleFileInput;
